/**
 * Isomorphic settings file (hence not typescript and commonjs notation)
 */

module.exports.urls = {
  authors: 'authors',
  // articles: 'articles',
  things: 'things',
  netlify: {
    preview: '/.netlify/functions/preview',
  },
  linkedin: 'https://www.linkedin.com/in/julienboucharlat/',
  instagram: 'http://instagram.com/',
  dribbble: 'http://dribbble.com/',
  twitter: 'https://twitter.com/',
  facebook: 'https://www.facebook.com/',
  gitlab: 'https://gitlab.com/j.boucharlat',
}

module.exports.imageQuality = 100

/**
 * What size do we want to cut hero images to?
 */
module.exports.heroImageDimensions = [
  ['CardPreview', 1200, 680],
  ['Article__Featured', 1200, 825],
  ['Article__Hero', 3200, 2200],
  ['Thing__Featured', 1200, 825],
  ['Thing__Hero', 3200, 2200],
]
