export const SHORTCUT: string = 'SHORTCUT'

export const COMMAND_LINE: string = 'COMMAND_LINE'

export const COMMAND_LINE_DEFAULT: string = 'COMMAND_LINE_DEFAULT'
export const COMMAND_LINE_READ: string = 'COMMAND_LINE_READ'
export const COMMAND_LINE_OPEN_SOURCE: string = 'COMMAND_LINE_OPEN_SOURCE'

export const GO_TO_HOME: string = 'GO_TO_HOME'
export const GO_TO_ABOUT: string = 'GO_TO_ABOUT'
export const GO_TO_THINGS: string = 'GO_TO_THINGS'
export const GO_TO_THING: string = 'GO_TO_THING'

export const GO_TO_GITHUB: string = 'GO_TO_GITHUB'

export const GO_TO_LINKEDIN: string = 'GO_TO_LINKEDIN'

export const CONTACT: string = 'CONTACT'

export const ESCAPE: string = 'ESCAPE'
