import React from 'react'
import styled from 'styled-components'

import media from '@styles/media'

const LogoSymbolContainer = styled.div`
  height: 30px;
  width: 23px;

  ${media.phablet`
    height: 25px;
    width: 19.09px;
  `};
`
/**
 * An inline SVG for the Logo with aria labels
 *
 * @param {String} fill dark or light
 */
const Logo = ({
  fill = '#fff',
  onlySymbol = false,
}: {
    fill?: string
    onlySymbol?: boolean
  }) => {
  if (onlySymbol) {
    return (
      <LogoSymbolContainer>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <title>J.Portfolio</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.8571 37.1429C28.8571 41.8095 27.7619 45.2381 25.5714 47.4286C23.3809 49.619 20 50.7143 15.4286 50.7143C10.9048 50.7143 6.09524 50.4762 1 50V40.3571C4.71429 40.5952 8.52381 40.7143 12.4286 40.7143C13.5238 40.7143 14.381 40.4048 15 39.7857C15.6667 39.119 16 38.2381 16 37.1429V10H6.71428V0H28.8571V37.1429Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36 39.2857H49V50H36V39.2857Z"
            fill={fill}
          />
        </svg>
      </LogoSymbolContainer>
    )
  }

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 352 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      style={{ pointerEvents: 'none' }}
    >
      <title>J.Portfolio</title>
      <path
        d="M27.8571 41.1429C27.8571 45.8095 26.7619 49.2381 24.5714 51.4286C22.3809 53.619 19 54.7143 14.4286 54.7143C9.90476 54.7143 5.09524 54.4762 0 54V44.3571C3.71429 44.5952 7.52381 44.7143 11.4286 44.7143C12.5238 44.7143 13.381 44.4048 14 43.7857C14.6667 43.119 15 42.2381 15 41.1429V14H5.71428V4H27.8571V41.1429Z"
        fill={fill}
      />
      <path
        d="M35 43.2857H48V54H35V43.2857Z"
        fill={fill}
      />
      <path
        d="M83.4232 2.38688C89.527 2.38688 94.0531 3.62498 97.0015 6.10117C100.002 8.52577 101.502 12.0079 101.502 16.5476V18.4047C101.502 23.2539 100.053 26.994 97.1567 29.625C94.26 32.2559 89.8891 33.5714 84.0439 33.5714C81.7162 33.5714 79.2333 33.3651 76.5952 32.9524V54H70V2.77379C74.3968 2.51585 78.8712 2.38688 83.4232 2.38688ZM83.268 7.95831C80.992 7.95831 78.7677 8.0099 76.5952 8.11307V27.3035C79.6988 27.7162 82.0783 27.9226 83.7335 27.9226C91.1822 27.9226 94.9066 24.6468 94.9066 18.0952V16.7797C94.9066 13.8393 93.9755 11.6468 92.1133 10.2024C90.2511 8.70633 87.3027 7.95831 83.268 7.95831Z"
        fill={fill}
        className="hide-on-mobile"
      />
      <path
        d="M126 15.3095C130.604 15.3095 134.018 16.5476 136.242 19.0238C138.518 21.4484 139.656 24.9047 139.656 29.3928V40.8452C139.656 45.3333 138.518 48.8155 136.242 51.2916C134.018 53.7162 130.604 54.9285 126 54.9285C121.397 54.9285 117.957 53.7162 115.681 51.2916C113.457 48.8155 112.344 45.3333 112.344 40.8452V29.3928C112.344 24.9047 113.457 21.4484 115.681 19.0238C117.957 16.5476 121.397 15.3095 126 15.3095ZM126 20.8035C123.776 20.8035 122.043 21.5 120.802 22.8928C119.56 24.2857 118.94 26.2718 118.94 28.8512V41.3869C118.94 43.9662 119.56 45.9524 120.802 47.3452C122.043 48.7381 123.776 49.4345 126 49.4345C128.225 49.4345 129.958 48.7381 131.199 47.3452C132.44 45.9524 133.061 43.9662 133.061 41.3869V28.7738C133.061 26.1944 132.44 24.2341 131.199 22.8928C129.958 21.5 128.225 20.8035 126 20.8035Z"
        fill={fill}
        className="hide-on-mobile"
      />
      <path
        d="M170.125 15.3095C170.849 15.3095 171.418 15.3611 171.832 15.4643V22.8928C171.056 22.7381 170.047 22.6607 168.806 22.6607C165.185 22.6607 162.314 24.4662 160.193 28.0774V54H153.598V16.2381H159.805V21.8869C161.202 19.7718 162.753 18.1468 164.46 17.0119C166.219 15.877 168.107 15.3095 170.125 15.3095Z"
        fill={fill}
        className="hide-on-mobile"
      />
      <path
        d="M192.287 16.2381H202.374V21.6547H192.287V43.7857C192.287 45.746 192.675 47.1647 193.451 48.0416C194.279 48.867 195.546 49.2797 197.253 49.2797C199.425 49.2797 201.546 49.0218 203.615 48.5059L203.77 54C201.494 54.619 199.425 54.9285 197.563 54.9285C193.425 54.9285 190.399 54.0258 188.485 52.2202C186.623 50.3631 185.692 47.371 185.692 43.244V21.6547H180.26V16.2381H185.692L186.933 6.17855H192.287V16.2381Z"
        fill={fill}
        className="hide-on-mobile"
      />
      <path
        d="M229.728 0.220215C231.435 0.220215 233.064 0.400771 234.616 0.761884V6.02379C233.064 5.76585 231.46 5.63688 229.805 5.63688C228.202 5.63688 226.934 6.15276 226.003 7.1845C225.124 8.21625 224.684 9.78966 224.684 11.9047V16.2381H232.443V21.6547H224.684V54H218.089V21.6547H212.658V16.2381H218.089V12.2916C218.141 8.26783 219.149 5.24998 221.115 3.23807C223.081 1.22617 225.951 0.220215 229.728 0.220215Z"
        fill={fill}
        className="hide-on-mobile"
      />
      <path
        d="M255.252 15.3095C259.856 15.3095 263.27 16.5476 265.494 19.0238C267.77 21.4484 268.908 24.9047 268.908 29.3928V40.8452C268.908 45.3333 267.77 48.8155 265.494 51.2916C263.27 53.7162 259.856 54.9285 255.252 54.9285C250.649 54.9285 247.209 53.7162 244.933 51.2916C242.709 48.8155 241.596 45.3333 241.596 40.8452V29.3928C241.596 24.9047 242.709 21.4484 244.933 19.0238C247.209 16.5476 250.649 15.3095 255.252 15.3095ZM255.252 20.8035C253.028 20.8035 251.295 21.5 250.054 22.8928C248.812 24.2857 248.192 26.2718 248.192 28.8512V41.3869C248.192 43.9662 248.812 45.9524 250.054 47.3452C251.295 48.7381 253.028 49.4345 255.252 49.4345C257.477 49.4345 259.21 48.7381 260.451 47.3452C261.692 45.9524 262.313 43.9662 262.313 41.3869V28.7738C262.313 26.1944 261.692 24.2341 260.451 22.8928C259.21 21.5 257.477 20.8035 255.252 20.8035Z"
        fill={fill}
        className="hide-on-mobile"
      />
      <path
        d="M289.445 1.14879V54H282.85V1.14879H289.445Z"
        fill={fill}
        className="hide-on-mobile"
      />
      <path
        d="M310.752 16.2381V54H304.157V16.2381H310.752ZM307.416 0.994024C308.812 0.994024 309.873 1.30355 310.597 1.9226C311.373 2.54165 311.761 3.41863 311.761 4.55355V6.7976C311.761 7.93252 311.373 8.8095 310.597 9.42855C309.873 9.99601 308.812 10.2797 307.416 10.2797C306.071 10.2797 305.01 9.99601 304.234 9.42855C303.51 8.8095 303.148 7.93252 303.148 6.7976V4.55355C303.148 3.41863 303.51 2.54165 304.234 1.9226C305.01 1.30355 306.071 0.994024 307.416 0.994024Z"
        fill={fill}
        className="hide-on-mobile"
      />
      <path
        d="M338.344 15.3095C342.948 15.3095 346.362 16.5476 348.586 19.0238C350.862 21.4484 352 24.9047 352 29.3928V40.8452C352 45.3333 350.862 48.8155 348.586 51.2916C346.362 53.7162 342.948 54.9285 338.344 54.9285C333.74 54.9285 330.3 53.7162 328.024 51.2916C325.8 48.8155 324.688 45.3333 324.688 40.8452V29.3928C324.688 24.9047 325.8 21.4484 328.024 19.0238C330.3 16.5476 333.74 15.3095 338.344 15.3095ZM338.344 20.8035C336.12 20.8035 334.387 21.5 333.145 22.8928C331.904 24.2857 331.283 26.2718 331.283 28.8512V41.3869C331.283 43.9662 331.904 45.9524 333.145 47.3452C334.387 48.7381 336.12 49.4345 338.344 49.4345C340.568 49.4345 342.301 48.7381 343.543 47.3452C344.784 45.9524 345.405 43.9662 345.405 41.3869V28.7738C345.405 26.1944 344.784 24.2341 343.543 22.8928C342.301 21.5 340.568 20.8035 338.344 20.8035Z"
        fill={fill}
        className="hide-on-mobile"
      />
    </svg>
  )
}

export default Logo
